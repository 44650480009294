import { ComponentProps, FC, useState } from 'react'
import clsx from 'clsx'

export const EventCardLoading: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  const [titleWidth] = useState(200)
  const [subtitleWidth] = useState(200)
  const [locationWidth] = useState(110)
  const [eventDateWidth] = useState(105)
  const [linesOfDescription] = useState(4)

  const description = []
  for (let i = 0; i < linesOfDescription; i++) {
    description.push(<div key={i} className="h-3 bg-slate-200" />)
  }

  return (
    <div
      className={clsx(
        'px-5 py-6 flex flex-col gap-2 bg-white rounded-lg shadow',
        'lg:flex-row',
        className,
      )}
      {...props}
    >
      <div className="hidden lg:block bg-slate-200 w-[78px] h-[72px] shrink-0 rounded" />

      <div className="flex-1 flex flex-col gap-2">
        <DesktopHeader
          titleWidth={titleWidth}
          subtitleWidth={subtitleWidth}
          locationWidth={locationWidth}
          eventDateWidth={eventDateWidth}
        />
        <MobileHeader
          titleWidth={titleWidth}
          subtitleWidth={subtitleWidth}
          locationWidth={locationWidth}
          eventDateWidth={eventDateWidth}
        />

        <div className={clsx('flex flex-col gap-4', 'lg:flex-row')}>
          <div className="flex-1 space-y-0.5">{description}</div>
          <div className="w-full lg:w-20 h-[30px] shrink-0 self-end bg-slate-200 rounded" />
        </div>
      </div>
    </div>
  )
}

const DesktopHeader: FC<{
  titleWidth: number
  subtitleWidth: number
  locationWidth: number
  eventDateWidth: number
}> = ({ titleWidth, subtitleWidth, locationWidth, eventDateWidth }) => {
  return (
    <div className={clsx('hidden', 'lg:flex lg:flex-col lg:gap-1')}>
      <div className="flex gap-4 items-center">
        <div className="flex-1 h-5">
          <div className="bg-slate-200 h-5" style={{ width: titleWidth }} />
        </div>

        <div className="flex gap-4 self-start">
          <span className="bg-slate-200 h-5 rounded-full" style={{ width: locationWidth }} />
          <span className="bg-slate-200 h-5" style={{ width: eventDateWidth }} />
        </div>
      </div>
      <div className="bg-slate-200 h-5" style={{ width: subtitleWidth }} />
    </div>
  )
}

const MobileHeader: FC<{
  titleWidth: number
  subtitleWidth: number
  locationWidth: number
  eventDateWidth: number
}> = ({ titleWidth, subtitleWidth, locationWidth, eventDateWidth }) => {
  return (
    <div className="space-y-4 lg:hidden">
      <div className="grid grid-cols-[min-content_1fr] grid-rows-[min-content_1fr] gap-4">
        <div className="row-span-2">
          <div className="lg:hidden bg-slate-200 w-[78px] h-[72px] shrink-0 rounded" />
        </div>
        <div className="justify-self-start">
          <div className="bg-slate-200 h-5 rounded-full" style={{ width: locationWidth }} />
        </div>
        <div className="bg-slate-200 h-5" style={{ width: eventDateWidth }} />
      </div>

      <div className="space-y-1">
        <div className="flex-1 h-5">
          <div className="bg-slate-200 h-5" style={{ width: titleWidth }} />
        </div>
        <div className="bg-slate-200 h-5" style={{ width: subtitleWidth }} />
      </div>
    </div>
  )
}

export default EventCardLoading
