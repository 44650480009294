import React, { ComponentProps, FC } from 'react'
import { Event } from 'api/cms'
import clsx from 'clsx'
import { useEventCardDate } from '@perry/app/components/content/EventCard'
import { Button } from '@perry/app/components/forms'
import { EventsEmptyStateWidget } from './EventsEmptyStateWidget'

const Location: FC<ComponentProps<'div'>> = ({ children, className, ...props }) => {
  return (
    <div
      className={clsx(
        'flex gap-2 items-center py-0.5 px-2.5 text-xs leading-4 font-medium bg-yellow-100 rounded-full',
        className,
      )}
      {...props}
    >
      <span className="h-1.5 w-1.5 bg-yellow-400 rounded-full inline-block"></span>
      <span className="text-yellow-800">{children}</span>
    </div>
  )
}

interface AvatarProps extends ComponentProps<'div'> {
  src: string
}

const Avatar: FC<AvatarProps> = ({ className, src }) => {
  return (
    <div className={clsx('flex items-center justify-center shrink-0 w-[78px] h-[72px]', className)}>
      <img className=" rounded h-full w-full object-left-top object-cover" src={src} />
    </div>
  )
}

const Card: FC<Event> = (event) => {
  const eventDate = useEventCardDate(event)
  return (
    <div className="px-6 py-5 space-y-4 bg-white shadow rounded-lg">
      <div className="flex gap-2">
        <Avatar src={event?.image?.url} />
        <div className="flex flex-col">
          <h3 className="text-rangitoto-500 text-sm leading-5 font-bold max-h-5 text-ellipsis overflow-hidden mb-1">
            {event.title}
          </h3>
          {event?.subTitle ? (
            <h5 className="text-rangitoto-400 text-sm leading-5 font-normal max-h-[60px] text-ellipsis overflow-hidden">
              {event.subTitle}
            </h5>
          ) : null}
        </div>
      </div>
      <div className="flex mt-4 justify-between items-end">
        <div>
          <Location className="mb-2 w-fit mr-auto">{event.location || 'TBD'}</Location>
          <div className="text-rangitoto-400 text-sm leading-none font-normal">{eventDate}</div>
        </div>
        <Button
          className="w-auto shrink-0 whitespace-nowrap"
          href={`/events/${event.id}`}
          uiType="secondary"
          size="xs"
        >
          View more
        </Button>
      </div>
    </div>
  )
}

interface EventsProps {
  readonly events: Event[]
}

export const Events: FC<EventsProps> = ({ events }) => {
  const empty = events.length === 0
  return empty ? (
    <EventsEmptyStateWidget />
  ) : (
    <>
      {events.map((event) => (
        <Card key={event.id} {...event} />
      ))}
    </>
  )
}
