import { useMemo } from 'react'
import { Event } from 'api/cms'
import { format } from 'date-fns'

export const useEventCardDate = (event: Event): string => {
  return useMemo(() => {
    if (event.eventDateTbd) {
      return event.eventDateTbdText || 'Date: TBD'
    }

    return format(new Date(event.eventDate), 'MMM do, h:mmaaa')
  }, [event])
}
