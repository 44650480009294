import { ComponentProps, FC, MouseEventHandler } from 'react'
import Link from 'next/link'
import { Event } from 'api/cms'
import clsx from 'clsx'
import { MarkdownSectionComponent } from '@perry/app/components/content/MarkdownProse'
import { Button } from '@perry/app/components/forms'
import { useEventCardDate } from './useEventCardDate'

export interface EventProps extends ComponentProps<'div'> {
  readonly event: Event
}

export const EventCard: FC<EventProps> = ({ event, className, ...props }) => {
  return (
    <div
      className={clsx(
        'px-5 py-6 flex flex-col gap-2 bg-white rounded-lg shadow',
        'lg:flex-row',
        className,
      )}
      {...props}
    >
      {event.image && (
        <Link href={`/events/${event.id}`}>
          <Avatar className="hidden rounded lg:block" src={event.image.url} />
        </Link>
      )}

      <div className="flex-1 flex flex-col gap-2">
        <DesktopHeader event={event} />
        <MobileHeader event={event} />

        <div className={clsx('flex flex-col gap-4', 'lg:flex-row')}>
          {event?.description ? (
            <MarkdownSectionComponent
              className="flex-1 max-w-none prose-p:text-xs text-deep-teal-300"
              markdown={event.description}
            />
          ) : (
            <p className="flex-1 text-xs text-deep-teal-300">
              No information yet. Please come back later.
            </p>
          )}
          <Button
            className={clsx('w-full shrink-0 whitespace-nowrap self-end', 'lg:w-auto')}
            href={`/events/${event.id}`}
            uiType="secondary"
            size="xs"
          >
            View more
          </Button>
        </div>
      </div>
    </div>
  )
}

const DesktopHeader: FC<{ event: Event; onTitleClick?: MouseEventHandler<any> | undefined }> = ({
  event,
}) => {
  const eventDate = useEventCardDate(event)

  return (
    <div className={clsx('hidden', 'lg:flex lg:flex-col lg:gap-1')}>
      <div className="flex gap-4 items-center">
        <Link
          href={`/events/${event.id}`}
          className="cursor-pointer hover:text-deep-teal-400 flex-1 text-sm text-deep-teal-500 font-bold"
        >
          {event.title}
        </Link>
        <div className="flex gap-4 self-start">
          <Location>{event.location}</Location>
          <span className="text-sm text-deep-teal-300">{eventDate}</span>
        </div>
      </div>
      {event?.subTitle && <div className="text-sm text-deep-teal-300">{event?.subTitle}</div>}
    </div>
  )
}

const MobileHeader: FC<{ event: Event; onTitleClick?: MouseEventHandler<any> | undefined }> = ({
  event,
}) => {
  const eventDate = useEventCardDate(event)

  return (
    <div className="space-y-4 lg:hidden">
      <div className="grid grid-cols-[min-content_1fr] grid-rows-[min-content_1fr] gap-4">
        <div className="row-span-2">
          <Link href={`/events/${event.id}`}>
            <Avatar src={event.image?.url} />
          </Link>
        </div>
        <div className="justify-self-start">
          <Location>{event.location}</Location>
        </div>
        <div className="text-sm text-deep-teal-300">{eventDate}</div>
      </div>

      <div className="space-y-1">
        <Link
          href={`/events/${event.id}`}
          className="cursor-pointer hover:text-deep-teal-400 flex-1 text-sm text-deep-teal-500 font-bold"
        >
          {event.title}
        </Link>
        {event?.subTitle && <div className="text-sm text-deep-teal-300">{event?.subTitle}</div>}
      </div>
    </div>
  )
}

const Avatar: FC<{
  src: string
  className?: string
}> = ({ className, src }) => {
  return (
    <div
      className={clsx(
        'cursor-pointer flex relative items-center justify-center w-[78px] h-[72px]',
        className,
      )}
    >
      <img
        className="hover:shadow-lg shrink-0 h-full w-full object-left-top object-cover rounded shadow"
        src={src}
      />
    </div>
  )
}

const Location: FC<ComponentProps<'span'>> = ({ children, className, ...props }) => {
  return (
    <span
      className={clsx(
        'flex gap-1 items-center py-0.5 px-2 text-xs font-medium bg-amber-100 rounded-full',
        className,
      )}
      {...props}
    >
      <span className="h-4 w-2 flex justify-center items-center">
        <span className="h-1.5 w-1.5 bg-amber-400 rounded-full inline-block"></span>
      </span>
      <span className="text-amber-800 font-medium px-0.5">{children}</span>
    </span>
  )
}

export default EventCard
