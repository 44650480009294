import { ComponentProps, FC } from 'react'
import Link from 'next/link'
import clsx from 'clsx'

export type EmptyStateWidgetComponentProps = Pick<ComponentProps<'div'>, 'className'>

export const EventsEmptyStateWidget: FC<EmptyStateWidgetComponentProps> = ({ className }) => (
  <div className={clsx('flex bg-white rounded-lg shadow py-6 px-5', className)}>
    <div className="flex items-top gap-2">
      <Logo className="w-8 h-8 shrink-0" />
      <div className="flex-col text-left">
        <h3 className="text-rangitoto-700 text-sm leading-5 font-bold mb-1">
          There are no events scheduled
        </h3>
        <p className="text-rangitoto-400 text-sm leading-5 font-normal">
          Please check back soon, to see previous events{' '}
          <Link
            href="/events?status=past"
            className="text-orange-600 font-semibold hover:text-orange-800"
          >
            click here
          </Link>
          .
        </p>
      </div>
    </div>
  </div>
)

const Logo: FC<ComponentProps<'svg'>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <rect width="32" height="32" rx="4" fill="white" />
    <path
      d="M9 7V11M7 9H11M10 21V25M8 23H12M17 7L19.2857 13.8571L25 16L19.2857 18.1429L17 25L14.7143 18.1429L9 16L14.7143 13.8571L17 7Z"
      stroke="#CBCECA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
