import { FC, useEffect, useState } from 'react'
import { NextRouter, useRouter } from 'next/router'
import { ApiEndpoint, ApiRegion } from '@amityco/js-sdk'
import { axios } from 'api/lib'
import { useSession } from 'auth'
import create from 'zustand'
import { AmityUiKitProvider } from './AmityUiKitProvider'

export interface AmityUiKitProviderStore {
  readonly connected: boolean
  readonly setConnected: (v: boolean) => void
}

export const useAmityProviderStore = create<AmityUiKitProviderStore>((set) => ({
  connected: false,

  setConnected(value: boolean) {
    set({ connected: value })
  },
}))

type Options = {
  actionHandlers?: { onClickUser?: (id: string) => void; onClickCommunity?: (id: string) => void }
}

const withAmityUiKitProvider = <P extends Record<string, unknown> & Options>(
  Component: any, // ComponentType<P>,
): FC<P> => {
  // eslint-disable-next-line react/display-name
  return (props: P): JSX.Element => {
    const { user } = useSession()
    const router = useRouter()
    const [token, setToken] = useState(null)
    const store = useAmityProviderStore()
    const userId = user?.id.toString()

    useEffect(() => {
      getAuthenticationToken().then((t) => {
        setToken(t)
      })
    }, [])

    return (
      <>
        {token && (
          <AmityUiKitProvider
            key={userId}
            apiKey={process.env.NEXT_PUBLIC_AMITY_API_KEY}
            apiRegion={ApiRegion.US}
            apiEndpoint={ApiEndpoint.US}
            userId={userId}
            authToken={token}
            actionHandlers={{
              onClickUser: (id: string) => navigateToMemberProfile(id, router),
              onClickCommunity: (id: string) => handleCommunityClick(id, router),
              ...(props.actionHandlers || {}),
            }}
            onConnected={() => store.setConnected(true)}
          >
            <Component {...props} />
          </AmityUiKitProvider>
        )}
      </>
    )
  }
}

export const navigateToMemberProfile = (id: string, router: NextRouter): void => {
  if (Number.isInteger(Number.parseInt(id))) {
    router.push(`/members/${id}`)
  }
}

export const handleCommunityClick = async (groupId: string, router: NextRouter): Promise<void> => {
  const { data: ioId } = await axios.get<number>(`/api/investment-opportunities/groupId/${groupId}`)

  if (ioId) {
    router.push(`/investments/${ioId}`)
  } else {
    router.push(`/groups/${groupId}`)
  }
}

export default withAmityUiKitProvider

const getAuthenticationToken = async (): Promise<string> => {
  const { data } = await axios.get<string>('/api/amity/token')
  return data
}
