/* istanbul ignore file */

import dynamic from 'next/dynamic'
import withAmityUiKitProvider, { useAmityProviderStore } from '../withAmityUiKitProvider'

const AmityUiKitFeed: any = dynamic(
  () => import('@meetperry/amity-uikit').then((m) => m.AmityUiKitFeed),
  {
    ssr: false,
  },
)

export const AmityUiKitFeedWithProvider = withAmityUiKitProvider((props): any => {
  const { connected } = useAmityProviderStore()
  return (
    <>
      <style jsx global>{`
        .amity-uikit-feed a {
          text-decoration: underline;
        }
      `}</style>
      {connected && <AmityUiKitFeed className="amity-uikit-feed" {...props} />}
    </>
  )
})
