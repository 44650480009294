import { ComponentProps, FC } from 'react'
import Link, { LinkProps } from 'next/link'
import { PostTargetType } from '@amityco/js-sdk'
import { ArrowNarrowRightIcon } from '@heroicons/react/outline'
import { AmityUiKitFeedWithProvider } from 'amity/components'
import { InvestmentOpportunity, Event } from 'api/cms'
import { useAxios } from 'api/lib'
import { useSession } from 'auth'
import clsx from 'clsx'
import { startOfToday } from 'date-fns'
import { GroupsWidget, InvestmentOpportunities } from '@perry/app/components/content'
import { MainLayout } from '@perry/app/components/layouts/MainLayout'
import { Events } from './Events'

const MAX_OPPORTUNITIES_ON_THE_PAGE = 4
const MAX_EVENTS_ON_THE_PAGE = 5

const useOpportunities = (limit = MAX_OPPORTUNITIES_ON_THE_PAGE) => {
  // URL is the unique key for the internal SWR cache. If we move "limit" to params,
  // URL will exactly match the one on the All Investment Opportunities page. This leads to
  // caching response between these two pages. To avoid this we make "limit" part of the key.
  return useAxios<InvestmentOpportunity[]>(`/api/investment-opportunities?limit=${limit}`)
}

const useEvents = (minEventDate: Date = startOfToday(), limit = MAX_EVENTS_ON_THE_PAGE) => {
  // URL is the unique key for the internal SWR cache. If we move "limit" to params,
  // URL will exactly match the one on the All Investment Opportunities page. This leads to
  // caching response between these two pages. To avoid this we make "limit" part of the key.
  const url = new URLSearchParams({
    limit: limit.toString(),
    minEventDate: minEventDate.toISOString(),
  })
  return useAxios<Event[]>(`/api/events?${url}`)
}

const useOpportunitiesCount = () => {
  return useAxios<number>('/api/investment-opportunities/count')
}

const Main: FC = () => {
  const { user } = useSession()
  const { data: investmentOpportunities = [] } = useOpportunities()
  const { data: events = [] } = useEvents()
  const { data: investmentOpportunitiesCount = 0 } = useOpportunitiesCount()

  return (
    <MainLayout
      className="max-w-7xl mx-auto w-full p-4 sm:py-10 md:py-12 sm:px-6 lg:px-8"
      title="meetperry | Home"
    >
      <div className="mb-6 flex flex-col md:flex-row gap-4 justify-between md:items-center">
        {user?.firstName && (
          <h2 className="text-3xl font-brand lining-nums font-medium text-deep-teal-500">
            Welcome, {user.firstName}
          </h2>
        )}
        <GroupsWidget className="md:max-w-xl" />
      </div>
      <SectionHeadingContainer>
        <SectionHeading>
          <span className="relative">
            Investment Opportunities
            <HeadingCount count={investmentOpportunitiesCount} data-testid="io-count" />
          </span>
        </SectionHeading>

        {investmentOpportunitiesCount > MAX_OPPORTUNITIES_ON_THE_PAGE && (
          <ViewAllLink href="/investments" />
        )}
      </SectionHeadingContainer>
      <InvestmentOpportunities className="mt-2" investmentOpportunities={investmentOpportunities} />

      <div className="lg:grid lg:grid-cols-12 gap-6">
        <section className="mt-6 col-span-8 space-y-2">
          <SectionHeading>Content & Commentary</SectionHeading>
          <AmityUiKitFeedWithProvider targetType={PostTargetType.GlobalFeed} />
        </section>
        <section className="mt-6 col-span-4 space-y-2">
          <SectionHeadingContainer>
            <SectionHeading>Events</SectionHeading>
            {events.length > 0 && <ViewAllLink href="/events" />}
          </SectionHeadingContainer>
          <Events events={events} />
        </section>
      </div>
    </MainLayout>
  )
}

export default Main

const SectionHeadingContainer: FC<ComponentProps<'div'>> = ({ children }) => {
  return <div className="flex flex-col sm:flex-row sm:items-center justify-between">{children}</div>
}

const SectionHeading: FC<ComponentProps<'h3'>> = ({ className, children, ...props }) => {
  return (
    <h3 className={clsx('text-lg sm:text-xl text-deep-teal-900 font-medium', className)} {...props}>
      {children}
    </h3>
  )
}

interface HeadingCountProps extends ComponentProps<'span'> {
  count: number
}

const HeadingCount: FC<HeadingCountProps> = ({ count, ...props }) => {
  return count ? (
    <span
      className="absolute ml-2 px-2.5 py-0.5 rounded-full text-taupe-100 text-xs leading-4 font-semibold bg-deep-teal-300 top-0"
      {...props}
    >
      {count}
    </span>
  ) : null
}

const ViewAllLink: FC<LinkProps> = ({ href }) => {
  return (
    <Link
      href={href}
      className="self-end sm:self-center flex items-center text-sm font-semibold text-orange-600 hover:text-orange-800"
    >
      <span>View all</span>
      <ArrowNarrowRightIcon className="hidden md:block ml-1 w-5" />
    </Link>
  )
}
